import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)




const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/process',
    name: 'login',
    component: () => import('../views/ProcessView.vue')
  },
  {
    path: '/realname',
    name: 'login',
    component: () => import('../views/RealNameVerification.vue')
  },
  {
    path: '/home',
    name: 'home',
    // component: () => import('../views/HomeView.vue')
    component: () => import('../views/HomeViews.vue')
  },
  {
    path: '/count',
    name: 'count',
    // component: () => import('../views/HomeView.vue')
    component: () => import('../views/CountViews.vue')
  },
  {
    path: '/journal',
    name: 'journal',
    // component: () => import('../views/HomeView.vue')
    component: () => import('../views/JournalViews.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutViews.vue')
  },
  {
    path: '/search',
    name: 'about',
    component: () => import('../views/SearchView.vue')
  },
  {
    path: '/wardata',
    name: 'about',
    component: () => import('../views/WardataView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailPage.vue')
  }
  
]





const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']; // 不需验证的页面
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user-token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});
export default router
