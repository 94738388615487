import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.use(ElementUI);

Vue.config.productionTip = false


import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use( VueAxios , axios)


axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('user-token');
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.removeItem('user-token'); // 删除过期的 token
      this.$router.push({ name: 'login' }); // 引导用户重新登录
    }
    return Promise.reject(error);
  }
);





new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
